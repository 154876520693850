import React from 'react';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { Box, Button, Grid, Link, Typography } from '@mui/material';
import { LANG_RU, NAMESPACES } from '../constants/locales';

const NS = NAMESPACES.error;

const NotFoundPage = ({ _nextI18Next }) => {
  const locale = _nextI18Next.initialLocale;

  const { t } = useTranslation();

  return (
    <Grid
      component="section"
      sx={{
        background: '#222b45',
        px: 4,
        py: 8,
        m: 'auto',
        width: '100%',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Box sx={{ display: 'grid', gridGap: '16px' }}>
        <Typography variant="h2" as="p" color="#951209">
          404
        </Typography>
        <Typography variant="h1">{t('Page not found')}</Typography>
        <Button
          href={locale === LANG_RU ? '/' : `/${locale}`}
          title={t('Home')}
          component={Link}
          variant="contained"
          sx={{ width: { xs: 'auto', sm: 'min-content' } }}
        >
          {t('Home')}
        </Button>
      </Box>
    </Grid>
  );
};

export const getStaticProps = async ({ locale }) => {
  return {
    props: {
      ...(await serverSideTranslations(locale, [NS, NAMESPACES.common]))
    }
  };
};

export default NotFoundPage;
